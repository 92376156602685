///----------------RRSSS------------



.facebook {
    border: 1px solid #465c8b !important;
    background: #465c8b !important;
    background-image: none !important;
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    a{
        background-color: white !important;
        -webkit-mask-image: url(../img/facebook.svg);
        background-image: none !important;
        width: 26px;
        height: 26px !important;
        &:before{
            content: none;
        }
    }
    &:hover{
        background-color: $accentColor !important;
        border: 1px solid white !important;
        a{
            background-color: white !important;
            -webkit-mask-image: url(../img/facebook.svg);
        }
    }
}


  
.twitter {
    border: 1px solid #86a7e7 !important;
    background: #86a7e7 !important;
    background-image: none !important;
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    a{
        background-color: white !important;
        -webkit-mask-image: url(../img/twitter.svg);
        background-image: none !important;
        width: 26px;
        height: 26px !important;
        &:before{
            content: none;
        }
    }
    &:hover{
        background-color: $accentColor !important;
        border: 1px solid white !important;
        a{
            background-color: white !important;
            -webkit-mask-image: url(../img/twitter.svg);
        }
    }
}
  
.googleplus {
    border: 1px solid #d34b3c !important;
    background: #d34b3c !important;
    background-image: none !important;
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    a{
        background-color: white !important;
        -webkit-mask-image: url(../img/gplus.svg);
        background-image: none !important;
        width: 26px;
        height: 26px !important;
        &:before{
            content: none;
        }
    }
    &:hover{
        background-color: $accentColor !important;
        border: 1px solid white !important;
        a{
            background-color: white !important;
            -webkit-mask-image: url(../img/gplus.svg);
        }
    }
}

.pinterest {
    border: 1px solid #b63232 !important;
    background: #b63232 !important;
    background-image: none !important;
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    a{
        background-color: white !important;
        -webkit-mask-image: url(../img/pinterest.svg);
        background-image: none !important;
        width: 26px;
        height: 26px !important;
        &:before{
            content: none;
        }
    }
    &:hover{
        background-color: $accentColor !important;
        border: 1px solid white !important;
        a{
            background-color: white !important;
            -webkit-mask-image: url(../img/pinterest.svg);
        }
    }
}

.instagram {
    border: 1px solid transparent !important;
    background-image: -webkit-linear-gradient(45deg, rgb(85, 107, 231) 0%, rgb(168, 75, 168) 51%, rgb(209, 71, 138) 71%, rgb(244, 57, 76) 100%) !important;
    background-image: linear-gradient(45deg, rgb(85, 107, 231) 0%, rgb(168, 75, 168) 51%, rgb(209, 71, 138) 71%, rgb(244, 57, 76) 100%) !important;
    display: flex !important;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    a{
        background-color: white !important;
        -webkit-mask-image: url(../img/instagram.svg);
        background-image: none !important;
        width: 26px;
        height: 26px !important;
        &:before{
            content: none !important;
        }
    }
    &:hover{
        background-color: $accentColor !important;
        border: 1px solid white !important;
        background-image: none !important;
        transition: all 0.2s;
        a{
            background-color: white !important;
            -webkit-mask-image: url(../img/instagram.svg);
        }
    }
}

.rss{
    display: none !important;
}

.block-social {
    padding: 0;
    text-align: inherit;
    width: auto;
    background: $footerBg  !important;
    color: $colorTxt !important;
    ul{
        display: flex;
        justify-content: center;
        li {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 60% auto;
            background: transparent;
            cursor: pointer;
            display: inline-block;
            height: 3rem;
            margin: 0 0.75rem 0 0;
            width: 3rem;
            transition: background-color 0.2s, opacity 0.2s;
            border-radius: 25px;

            &:hover {
                border: none;
                box-shadow: none;
                opacity: 1;
                background-color: $accentColor;
            }
            
        }
    }
    
}

///------------------------

.block-footer-after{
    background: $footerBg !important;
    .logo {
        max-width: 235px;
        height: auto;
    }
}

.aeuc_footer_info{
    background: $footerBg !important;
    color: $colorTxt !important;
}

#footer,
body#checkout #footer {
    background-color: $footerBg;
    div.footer-container {
        .block-contact i {
            color: $accentColor;
        }
        h3,
        .h3 {
            color: $accentColor;
            a {
                color: inherit;
            }
        }
    }
}

.footer-before {
    @include text-contrast($footerBeforeBg);
    background-color: $footerBeforeBg;
    padding-bottom: 2.5rem;
}

.footer-container>.container {
    padding-top: 2rem;
    @include addBreakpoint(lg) {
        
    }
}

.footer-container span:hover ~ span {
    background: transparent !important;
}

#footer div.footer-container h3,
#footer div.footer-container .h3 {
    color: $footerBeforeBg;
    @media (max-width: $desktopBreakpoint) {
        font-size: 1rem;
    }
}

.footer-container>.container>.row {
    margin: 0;
    @include addBreakpoint(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.footer-container>.container .row {
    margin: 0;
}

#footer div.footer-container .block-contact i {
    @media (max-width: $desktopBreakpoint) {
       
    }
}

#footer .footer-container {
     h3 {
        margin-bottom: 0.5rem;
     }

}

.footer-container li {
    margin-bottom: 0.15rem;
}

.footer-container li {
    @include text-contrast($footerBg);
    opacity: 0.5;
    color: $footerColor;
    a {
        color: inherit;
    }
    &:hover {
        opacity: 1;
        a {
            color:$primaryColor !important;
            border-bottom: 1px solid $primaryColor;
        }
    }
}

.copy-right {
    margin-top: 20px;
}

.block-footer-after {
    @include text-contrast($blockFooterAfterBg);
    background-color: $blockFooterAfterBg;
    padding: 0 0 2.875rem;
    margin-top: 0;
}


.footer-after .block-social,
.footer-after div.aeuc_footer_info {
    @include text-contrast($footerEndBg);
    background-color: $footerEndBg;
    padding: 1.5rem 0 1rem;
}

.footer-after div.aeuc_footer_info {
    font-size: 0.7rem;
    margin: 0;
    padding: 0 0 1rem;
    margin-top: -1px;

    a {
        @include text-contrast($footerEndBg);
        text-decoration: underline;
        opacity: 0.5;
        transition: opacity 0.2s;
        &:hover {
            opacity: 1;
        }
    }
}

.footer-container .col-md-6.links {
    padding: 0;
    float: none;
    @include addBreakpoint(md) {
        width: calc(100% / 3);
    }
    >.row {
        @include addBreakpoint(xl) {
            display: flex;
            justify-content: space-between;
        }
    }

    @include addBreakpoint(xl) {
        width: 50%;
    }
}



.footer-container .wrapper {
    margin-top: 0;
    width: auto;
    float: none;
}

.footer-container .block-contact.col-md-3.links.wrapper {
    margin: 0 auto;
    width: 100%;

    @include addBreakpoint(md) {
        width: calc(100% / 4);
    }

    @include addBreakpoint(xl) {
        width: 25%;
    }
}

.category-tree {

    width: 100%;

    @include addBreakpoint(md) {
        width: calc(100% / 4);
    }
    @include addBreakpoint(xl) {
        width: 25%;
    }
}

.footer-container .links ul>li a {
    color: inherit;
}

.footer-container .links .title {
    border-bottom: none;
    text-align: left;
}



.footer-container .category-tree {
    h3.h3 {
        display: block;
    }
    ul {
        max-height: 150rem;
        transition: max-height 0.4s ease-in-out;
        overflow: hidden;
        height: auto;
    }
}



#bbfooter_togglecats {
    display: none;
}

label[for="bbfooter_togglecats"] {
    font-size: 0.875rem;
    align-items: center;
    width: 100&;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem 0.75rem 0.5rem 0.7rem;
    margin: 0;
    display: none;
}

@media (max-width: 767px) {
    .footer-container .block-contact i.material-icons.add {
        font-size: 24px;
    }
    .footer-container i.material-icons {
        color: $accentColor;
    }

    .footer-container>.container {
        padding: 1rem;
    }


   .footer-container .links a.h3 {
    max-width: calc(100% - 2rem);
    display: inline-block;
    line-height: 1;
    
}

.footer-container .links ul > li {
    padding: 0 0.625rem;
}



    .footer-container .category-tree {
        padding: 0;
        h3.h3 {
            display: none;
        }
        ul {
            padding: 0 0.75rem;
            max-height: 0;
            margin: 0;
        }

        #bbfooter_togglecats:checked~label i {
            transform: rotate(180deg);
        }

        #bbfooter_togglecats:checked~ul {
            max-height: 150rem;
        }
    }
    label[for="bbfooter_togglecats"] {
        display: flex;
    }
}