#header .bb_menu {
    .bb_menu-secudary {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid $borderMenuHeader;
        text-transform: uppercase;
    }
    .bb_menu-secundarylabel {
        @extend %menuSecundarylabel;
        width: auto;
        color: $txtColor;
        background: $premenuBackground;
        &:hover,
        &:focus {
            background-color: $accentColor;
            color: white;
        }
        

    }
}