// Los componentes son elementos de interfaz de usuario.
// Contienen CSS cosmético y se pueden sobrescribir directamente para adaptarse al estilo Kit de interfaz de usuario.

// Ejemplos:
// Button, modal

// https://www.iotacss.com/docs/components/

//----------------------
// components files here
//----------------------




@import "form";
@import "button";
@import "table";
@import "spinner";




