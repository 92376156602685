.bb_menu{
    .preheader{
        display: none;
    }
}

.preheader{
    background: $preheaderBackground;
    height: auto;
    .flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        li{
            padding: 0 10px;
            margin-left: 5px;
            margin-right: 5px;
            text-transform: uppercase;
            outline: none;
            text-decoration: none;
            position: relative;
            display: inline-block;
            overflow: hidden;
            color: #353b40;
            font-size: 12px;
            cursor: pointer;
            a{
                max-width: 130px;
                max-height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .ttxt:hover{
            a{
                border-bottom: 2px solid $preHeeaderBg;
                cursor: pointer;
                color: $preHeeaderBg;
            }
        }
        .timg{
            img{
                height: 37px;
                transition: all 0.5s ease;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: all 0.5s ease;
                }
            }
        }
        .timgtxt{
            img{
                width: 23px;
                margin-right: 5px;
                transition: all 0.5s ease;
            }
            
        }
    }
}


@media (max-width: 1110px) {
    //Para ocultar el preheader de escritorio en versión móvil
    .preheader{
        display: none;
     }
     .bb_menu{
        .preheader{
            display: block;
            height: auto;
            .flex-center{
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 0;
                padding-left: 0.7rem;
                li{
                    padding: 10px 0px;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}     