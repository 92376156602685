//------------
//
//  COLORS
//
//------------
//
$footerBg:white;
$footerColor: #000;
$footerContainerColor:#8a8a8a;
$footerContainerHoverColor:#000;
$blockFooterAfterBg: #e4e4e4;
$footerEndBg: #353535;
$txtColor: #666666;
$colorShadow:transparentize(black,0.92);

$wrapperColor: #fff;
//
//------------
//
//  MENU
//
//------------
//
$menuBg: $primaryColor;
$backgroundSecundary: #f4f3f0;
$borderMenuHeader: #f4f3f0;
$preheaderBackground: #f4f3f0;
$premenuBackground: #f4f3f0;

$menuSidesBg:$menuBg;

$menuSidesBg:transparent;


$menuItemHoverBg: $accentColor;
$menuItemHoverBg: darken($menuBg,10%);

$menuItemHoverShadow: mix($menuBg,white,35%);

$submenuArrowUpColor: transparent;

//------------
//
//  Newsletter
//
//------------
$newsletterinputenabledBackground : $primaryColor;
$newsletterinputenabledTextcolor : #000;
//------------
//
//  LINK HOVER
//
//------------
//
$linkHoverColor: $accentColor;
//
//------------
//
//  HEADER
//
//------------
//
//$headerBg:  darken($primaryColor, 70%);
$headerBg:  white;
$headerBackgroundColor:transparent;
$headerBorderBottom: 1px solid $colorShadow;
$headerImage: linear-gradient(transparentize($headerBg, 0.1), transparentize($headerBg, 0.02) 60%);

//
//------------
//
//  MOBILE MENU
//
//------------
//
$menuMobileBg:white;
$menuMobileSecundaryColor: $primaryColor;
$bgMobilefamilyBg: mix($primaryColor,white,5%);
$mobileDropdownItemLineColor:transparentize(black,0.8);
$categoryColor: $primaryColor;
//
//------------
//
//  POPOVER
//
//------------
//
$popOverBoxShadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
$pageShadow: transparentize(white,0.5);
$menuPopoverColor:black;
//------------
//
//  SUBMENU
//
//------------
//
$bgSubmenuBg:white;
$subMenuTextColor: #444;
$bgSubmenuColor:transparentize(black,0.3);
$bgSubmenuColor:#444;
$categoryColor: #444;
$menuBxShColor: transparentize(black,0.8);
$colorSubmenuLinks: $bgSubmenuColor;
$colorSubmenuLinksHover: $colorSubmenuLinks;
$bgSubmenuLinksHover: mix($menuBg,white,5%);
$tagsHoverBg: mix($menuBg,white,50%);
$tagsHoverColor: white;
$categoryLabelColor: black;
//
//------------
//
//  ARROWS
//
//------------
//

$bbArrowsBg: transparent;
$bbArrowsColor: black;
//
//------------
//
//  SUBMENU MOBILE
//
//------------
//
$submenuMobileBorderColor: black;
$submenuMobile2BorderColor: transparentize(black,0.6);
$mobileSubmenuBg: rgba(0,0,0,0.1);


@media (max-width: 767px) {

#header .header-nav .user-info .logged {
	color: $accentColor;
}


}