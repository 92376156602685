// Los objetos son selectores basados ​​en clases que definen patrones de diseño sin decorar como la rejilla o un objeto de medios. 
// Proporcionan estructura al contenido, no contienen ningún CSS cosmético y nunca deben sido sobrescritos directamente.

// Ejemplos:

// Container, grid, list, media, type
// https://www.iotacss.com/docs/objects/


//----------------------
// objects files here
//----------------------
