#block-reassurance {
    border: none;

    ul {
        font-size: 0;
    }


    span {
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0;
        padding-top: 0.25rem;
        text-transform: uppercase;
    }
    li {
        border: none;
        display: inline-block;
        width: auto;

        .block-reassurance-item {

            padding: 0.5rem 2rem 0.5rem 0;
        }
    }

    img {
        background-color: transparent;
        height: 1.5rem;
        margin: 0;
        opacity: 0.3;
        padding: 0;
        width: 1.5rem;
    }
}

#block-reassurance li .block-reassurance-item>span {
    display: inline-block;
    line-height: 1.1;
    padding-left: 0.4375rem;
    vertical-align: bottom;
    width: calc(100% - 1.5rem);
}


#cart #block-reassurance ul li {
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

#product #block-reassurance span {
    font-weight: 500;
}



#product #block-reassurance ul li {
    width: 100%;
}


@media screen and(min-width: 560px) {

    #product #block-reassurance ul li {
        width: auto;
        min-width: 50%;
    }
}




@media screen and(min-width: $mobileBreakpoint) {

    #product #block-reassurance ul li {
        min-width: 35%;
    }
}










@media screen and(min-width: 1200px) {

    #product #block-reassurance ul li {

        width: auto;
        min-width: 0;
    }
}