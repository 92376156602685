//-----------------------
// hacks
//-----------------------

#index #header{
    position: relative;
    top: 0 !important;
    left: inherit !important;
}

#header .header-nav #menu-icon .material-icons {
    color: $primaryColor !important;
}

.news-conditions{
    top: 30px;
    color: white;
    line-height: 15px;
}

.news-align{
    margin-bottom: 3rem;
}

@media only screen and (min-width: 1111px) {
    #header .bb_menu a.dropdown-item[data-depth="0"] {
        padding: 1rem 0.2rem 1rem 1rem;
        justify-content: flex-start;
    }
    #header .bb_menu .bb_menu-list[data-depth="0"]>li.category {
        margin-left: 0;
        margin-right: auto;
        max-width: 20%;
    }
    #header .header-top {
        padding-bottom: 1rem;
    }
}

.product-variants>.product-variants-item select {
    width: auto;
    min-width: 3.75rem;
}

@media only screen and (min-width: 1401px) {
    #header .bb_menu[data-js="bb_menu"] {
        max-width: 1300px;
    }
}

@media only screen and (max-width: 1400px) {
    #header .bb_menu[data-js="bb_menu"] {
        max-width: 1170px;
    }
}

@media only screen and (max-width: 1200px) {
    #header .bb_menu[data-js="bb_menu"] {
        max-width: 100%;
        padding: 0 10px;
    }

    #manufacturer #content-wrapper {
        position: relative;
        padding-left: 30px;
    }
  
    .news-align{
        margin-bottom: 4.1rem ;
    }
}

@media only screen and (min-width: 1200px){
    #manufacturer #content-wrapper {
        position: relative;
        padding-left: 30px;
        
    }
}

@media (max-width: $mobileBreakpoint) {
    #header .header-top .search-widget {
        margin-bottom: 0;
        padding: 0;
    }
    #wrapper {
        display: block !important;
        padding-top: 1rem;
    }
}

.owl-carousel .owl-item img {
    width: 100%;
    margin: 1.5rem auto 0;
}

@media (max-width:$desktopBreakpoint) {
    #index #header {
        position: static;
    }
    #header .bb_menu[data-js="bb_menu"] {
        max-width: 100%;
    }
}

#products .product-thumbnail {
    padding: 1.5rem 0 0;
    >img {
        display: block;
        margin: 0 auto;
    }
}

#products .product-miniature {

    margin-bottom: 15px;
}

#index #products .product-miniature {

    margin-bottom: 0;
}

#index .product-miniature {
    padding: 1rem;
}





.js-qv-product-cover {
    //min-height: 42rem;
    //min-width: 42rem;
    display: block;
    box-shadow: 0 0 0 1px $colorShadow;
    max-width:450px;
}



.ls-l.big_bluee,
.ls-l.very_big_white,
.ls-l.big_white {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.facet-label .material-icons {
    display: none;
}

@media (min-width: $desktopBreakpoint) {
    #g-header #header .bb_menu .bb_menu-list[data-depth="0"] {
        box-shadow: -30rem 0 $menuSidesBg, 30rem 0 $menuSidesBg, -30rem 1px $menuSidesBg, 30rem 1px $menuSidesBg; //box-shadow: none;
    }
    #header .bb_menu .bb_menu-list[data-depth="0"] {
        background-color: transparent;
        @include text-contrast($headerBg);
    }
    #header .bb_menu .bb_menu-list[data-depth="0"]>.category[data-js-active]>.dropdown-item {
        background-color: transparent;
        box-shadow: 0 -4px $accentColor inset;
        color: $menuBg;
    }
    #header .bb_menu #lnk-home:hover,
    #header .bb_menu #lnk-home:focus {
        background-color: transparent;
        color: $accentColor;
        &::after{
            color: #fff;
        }
    }
    /*.bb_menu-popover.sub-menu.js-sub-menu.collapse::after {
        background-color: $pageShadow;
        content: "";
        display: block;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;
        opacity: 0;
        transition: none;
        pointer-events:none;
    }

    
    }*/
}

#cookieNotice {

    font-size: 0.8rem;
    font-weight: 300;
    z-index: 60;
}

#cookiesClose {

    margin: 1.5rem auto 0;
    display: block;
}

.dropdown-item.dropdown-submenu br {
    display: none;
    font-size: 0;
    position: absolute;
}



#products .product-thumbnail>img[src=""] {

    box-shadow: 0 0 10px 1px $color-shadow inset;

    height: 250px;
    width: 100%;

    @media (min-width: 992px) {

        height: 195px;
    }




    @include addBreakpoint(xl) {


        height: 250px;
    }
}


.showLess li:nth-child(n+10) {
    display: none;
}


.facet .collapse .btn-tertiary {
    font-size: 1.75rem;
    line-height: 1;
    margin-top: 1.5rem;
    padding: 0.5rem 1.75rem;
    width: auto;
}

.owl-top-banner .bn-top-content {
    font-size: 0;
    img {
        width: 100%;
    }
}


#cart .js-cart-line-product-quantity {
    border: 1px solid gainsboro;
    padding: 0.5rem;
    max-width: 100%;
    height: 2.5rem;
    font-size: 16px;
}

#cart .material-icons.pull-xs-left {}

.product-line-grid-right .bootstrap-touchspin {

    box-shadow: none;
}

#_desktop_cart,
#_mobile_cart {

    margin-left: 1rem;
}

@media (max-width: 991px){
    .block_newsletter{
            padding-top: 2rem;
    }
    .news-align{
        margin-bottom: 3rem;
    }
}

@media (max-width: 767px) {

 

    #_desktop_cart {
        display: none;
    }
    .footer-container{
        .container{
            .row{
                .links{
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }
            }
        }
    }
}

.help-block {
    color: crimson;
}

.page-my-account #content .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem;
}


.page-my-account #content .links a,
.page-my-account #content .links li {
    box-shadow: none;
   
    padding: 0;
   // text-align: center;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center; 
    float: none;
    margin-bottom: 0.75rem;
    font-size: 0;
    padding: 0;
    line-height: 0;
    width: calc(100%/3 - 0.5rem);
    text-decoration: none;
    height: 7rem;
    color: $accentColor;
    box-shadow:0 0 0 2px rgba(0,0,0,0.1) inset;
    transition:box-shadow 0.2s,color 0.2s;
    &:hover{
        color: darken( $accentColor,30%);
        box-shadow: 0 0 0 2px darken( $accentColor,20%) inset;

        i{
           color: darken( $accentColor,30%); 
        }

    }
}


//-------------- Contacte con nosotros -------------------


#header .header-nav #contact-link{
   //background: red;
    line-height: 10px;
    text-align: right;
    a{
        color: #99939f; //AÑADIR VARIABLE
        font-size: 0.75rem;
        margin-left: 15px;
    }
}



//--------------------------------------------------------


.page-my-account #content .links a i,
.page-my-account #content .links li a:before {
    display: block;
    font-size: 2rem;
    width: 100%;
    color: inherit;   
    padding-bottom: 3rem;

}
.page-my-account #content .links a .link-item,
.page-my-account #content .links li a {
    font-size: 1rem;
    line-height: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
   
}





.page-my-account #content .links li a {
    &:before {
        @extend %material-icons;
        content: "\E002";
    }
    width: 100%;   
    margin: 0;
   // box-shadow: none;
    
}

body {
    overflow-x: hidden;
}


.page-my-account #content-wrapper .page-footer .text-xs-center > a{
    @extend .btn-primary;    
    display: inline-block;
    margin:0 auto;
    width: auto;
    padding: 0.5rem 1rem;
    //border:1px solid ;
    text-decoration: none;
}


@media (max-width: 991px) {
    .page-my-account #content .links a,
    .page-my-account #content .links li {

        width: calc(100%/2 - 0.5rem);
    }
}

@media (min-width: 544px){
     .filter-button{
        width: auto;
    }
}

@media (max-width: 543px) {
    .page-my-account #content .links a,
    .page-my-account #content .links li {

        width: 100%;
    }
}

.product-quantity .input-group-btn-vertical {
    position: relative;
    left: 0.1px;
}


.table-labeled .label[style*="background-color:#4169E1"] {
    background-color: mediumturquoise !important;
    display: flex;
    text-align: center;
}


.page-my-account #content .row .links {
    display: flex;
}
