#lnk-home {
    display: block;
    a[data-depth="0"] {
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 0;
        align-items: center;
        justify-content: flex-start;
        &:after {
            font-family: Material Icons;
            content: "\E88A";
            font-size: 1.25rem;
        }
    }
}

#header .bb_menu {
    font-family: $fontFamily;
    max-width: $maxWidthMenu;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;   
    a {
        transition: background-color 0.2s;
    }
    #lnk-home {
        //color: $menuColor;
        color: $primaryColor;
        a[data-depth="0"] {
            font-size: 0;
            justify-content: flex-start;
            color:inherit;
        }
        transition: background-color 0.2s;
        &:hover,
        &:focus {
            //color: $menuColorHover;
            background-color: $menuItemHoverBg;
            opacity: .7;
            @include text-contrast($menuItemHoverBg);
            outline: none;
            text-decoration: none;
        }
    }
    .bb_menu-list {
        display: flex;
        margin: 0;
        order: 1;
        padding: 0;
    }
    .bb_menu-list[data-depth="0"] {
        background-color: $menuBg;
        
        @include text-contrast($menuBg);
        position: relative;
    }
    .dropdown-item,
    .bb_menu-secundarylabel {
        @extend %menuSecundaryFont;         
        color: $subMenuTextColor;       
        background-color: transparent;
        display: flex;        
        white-space: normal;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        transition: background-color 0.2s;
        box-shadow: none;       
        flex-direction: row;
        &:hover,
        &:focus {
            outline: none;
            text-decoration: none;
            //color: $menuSecundaryColorHover;
        }
    }

    .dropdown-item[data-depth="0"] {
        @extend %menuPrimaryBtn;     
        //color: $menuColor;
    }

    .bb_menu-secudary {
        display: flex;
        flex-wrap:wrap;
        //flex-direction: column;
    }
    .bb_menu-secundarylabel {
        padding: 0.75rem;
        width: auto;
        color: $menuSecundaryColor;  
        transition: background-color 0.2s, color 0.2s;          
        &:hover,
        &:focus {
             background-color: red;             
             color: red;       
        }
    }
    
}

#header .bb_menu a.category-label.top {
    font-size: 1.5rem;
    left: 19.5rem;
    max-width: 75%;
    z-index: 0;
}

#header .bb_menu a.category-label {
    @extend %categorylabelFont;
    color: $categoryLabelColor;
    
}

.bb_menu-images-container {
    bottom: 0;
    display: none;
    position: absolute;
    right: 0;
    z-index: 0;
    height: calc(100% - 1px);
    transition: opacity 0.2s ease 0.2s;
    >img {
        height: 100%;
        width: auto;
    }
}

#header .bb_menu .bb_menu-list[data-depth="1"] .bb_menu-images-container {
    z-index: -1;
}

#index #wrapper .breadcrumb-container {
    margin-bottom: 0;
}
