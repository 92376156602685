@include block(category) {}





.block-category {
    position: relative;
    min-height: 190px;
    height: auto;
    padding: 1rem;
    display: block;

    .h1 {
        color: $categoryColor;
        margin-bottom: 0;
        position: relative;
    }
    .category-cover {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
    #category-description {
        margin-top: 0;
        position: relative;
        z-index: 1;
        max-width: calc(100% - 350px);
        margin-top: 1rem;
        line-height: 1.4;
        *[style*="background-color:#f1f2f3"] {
            background-color: transparent !important;
        }
    }
}

@media only screen and (max-width: 940px) {
    .block-category {
        display: none;
    }
}

.block-categories .category-sub-menu li[data-depth="0"]>a {  
    width: calc(100% - 1rem);
}


#products .products-selection {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#products .products-selection>div {
    padding: 0;
    width: auto;
    float: none;
}
















#products .products-selection .total-products {
    margin: 0;
    width: auto;
    flex-grow: 0;

    +.col-md-6 {
        flex-grow: 1;
        .row {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
           
            margin: 0;
            @include addBreakpoint(md) {
                 justify-content: end;
                
            }
        }
    }
}



.products-selection .sort-by {
    float: none;
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: nowrap;
    width: auto;
    padding-right: 1.25rem;
}
.products-sort-order { 
  padding: 0;
  width: auto;
}

#products .product-title a:hover {
  color: $accentColor;
}

.prod-content-right {
    width: 100%;
    padding: 0;
}

.product-minimal-quantity {
  //padding-bottom: 1rem;
}

.product-add-to-cart {
    //align-items: center;
    display: block;
    //flex-wrap: wrap;
    padding: 0;



    .product-quantity {
        align-items: center;
        display: flex;
        flex-wrap:wrap;
        width: 100%;

        .qty,
        .add {

            margin-bottom: 1rem;
        }
    }
}


/*.facet-label {
    display: flex;
    padding-bottom: 0.5rem;
}

#search_filters .facet .facet-label a {
    color: currentColor;
    text-align: left;
    line-height: 1.4;
    margin-left: 0.25rem;
    margin-top: 0.4rem;
}*/

#search_filters .facet-title {
    color: $primaryColor;
    font-size: 1.25rem;
    text-transform: uppercase;
}
    

#search_filters .facet .collapse {
    position: relative;
    li {
        &:nth-child(n+10) {
            display: none;
        }
    }
}

input[id*="togglefacet_"] {
    display: none;
}

label[for*="togglefacet_"] {
    width: 3rem;
    margin-top: 1rem;
    &:before {
        content: "+";
    }
}

#search_filters .facet input[id*="togglefacet_"]:checked {
    +.collapse li {
        &:nth-child(n+10) {
            display: block;
        }
    }

    ~label[for*="togglefacet_"] {

        &:before {
            content: "-";
        }
    }
}

.block-categories .collapse-icons .add:hover, .block-categories .collapse-icons .remove:hover {
    color: $accentColor;
}
#product-modal .modal-content .modal-body .product-images img:hover {
    border:  $accentColor 3px solid;
}



