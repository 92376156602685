@include block(bb_spinner) {

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100%;
    z-index: 100000000000;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease-out; 
    &:before{
    	content: "";
    	animation: bb_rotate 1.6s linear infinite;       
        width: 29px;
        height: 29px;
        display: block;
        border-radius:50%;
        border:3px dotted white;
    }

    @include element(active) {
    	opacity: 1; 
    	transition: opacity 0.4s ease-in ;         
    }
}

@keyframes bb_rotate {
  from {
    transform: rotate(0deg); 
  }
  
  to {
    transform: rotate(360deg);
  }
}


.add-to-cart {
	cursor: pointer;
}

