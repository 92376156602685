//.bb-elipsis{
   // outline: 1px solid red;
//}


//@media (max-width: 1199px) {

    .bb-elipsis {
       overflow: hidden;
       max-height: 3.6em;
    
}




//}