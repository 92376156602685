$colorTxt: #666666;

//------------
//
//  MENU SIZES
//
//------------
//
$maxWidthMenu: 1300px;
$desktopBreakpoint: 1110px;
$mobileBreakpoint: 768px;
//
$subMenuHeight: 460px;
$menuDesktopTop: 0;
$submenuArrowUpSize: 6px;
//
$menuMobileMinHeight: 3rem;
$preHeeaderBg: whitesmoke;
//------------
//
// SUBMENU POSITION
//
//------------
//
$submenuTop: 4.1rem;
$submenuFFTop: 4.2rem;
$menuImgContFF: 0;
//
//------------
//
//  FONTS Poppins:300,400,500,600,700";
//
//------------
//
$fontFamily: 'Poppins',
sans-serif;
$menuFontWeight:400;
$menuLineHeight: 1.25;
//
$menuFontSize: 1rem;
$subMenuFontSize: 0.8rem;
//
$menuMobileFontSize: 0.9rem;
$mobileMenuLineHeight: 1.2;
//
$categoryLabelFontSize:1.5rem;
$categoryLabelFontWeight: 400;
//------------
//
//  SHADOWS
//
//------------
//
$categoryLabelOpacity: 0.4;
$color-shadow: transparentize(black, 0.9);
$color-shadow-light: transparentize(black, 0.95);
$menuLinesColor: #eee;
$menuPrimaryBtnBoxShadow: none;
//
//------------
//

$submenuDepth1LinkPadding:0.5rem 0.5rem 0.5rem 0.75rem;


$submenuDepth1LinkPadding:0.5rem 1rem;


$submenuCollapsePadding: 3rem 0.75rem 0.2rem;

$submenuDepth2CollapsePadding: $submenuDepth1LinkPadding;


$submenuWidth:18rem;

$submenuContainerWidth: calc(100% - 18rem);



$pageShadowTransition: opacity 0.4s ease-out 0.2s;
$pageShadowTransition: opacity 0.8s ease-out 0.6s;
//
//------------
//
%mobileMenuMargin {
    margin: 1rem 0;
}
//
//------------
//
%menuFont {
    font-size: $menuFontSize;
    font-weight: $menuFontWeight;
    line-height: $menuLineHeight;
    letter-spacing: 0.05em;
}
//
//------------
//
%menuSecundaryFont {
    font-size: $subMenuFontSize;
    font-weight: $menuFontWeight;
    line-height: $menuLineHeight;
}
//
//------------
//
%categorylabelFont {
    font-size: $categoryLabelFontSize;
    font-weight: $categoryLabelFontWeight;
    letter-spacing: 0;
    padding: 0.7rem 0;
    position: absolute;
    left: 1.5rem;
    text-shadow: 0 0 2px white;
    top: 0.5rem;
    width: 95%;
    z-index: 3;
    opacity: $categoryLabelOpacity;
}
//
//------------
//
%menuPrimaryBtn {    
    font-weight: 400;

    font-size: 0.75rem;
    line-height: 1.1;
}



%menuSecundarylabel{
    padding: 1rem;

}


%material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}
