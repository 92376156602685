.bb_menu-images-container {
    display: none;
    transition: opacity 0.2s;
}

.category-label {
    display: none;
}

#header .bb_menu {
    display: none;
}


input[id*="toggle_"] {
    display: none;
}

.btn.canvas-menu:active {
    box-shadow: none;
}

.bb_menu-close {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparentize(black, 0.8);
    display: none;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
    opacity: 0;
}

.bb_menu-burguer {
    display: none;
    margin-right: 15px;
}

#_desktop_logo {
    margin-left: 0;
    margin-right: auto;
}

@media (max-width: 1800px ) {
    #index .santa-claus {
        display: none;
    }
}


@media (max-width: 1110px) {

    .category-tree {
        padding-top: 0.1rem !important;
    }

    #header .header-nav {
        background: $bgSubmenuBg;
    }

    #header .header-top {
        padding-bottom: 1.35rem;
    }

    #header .bb_menu {

        position: relative;
        max-width: 100vw;
        box-shadow: none;
        clear: both;
        .bb_menu-list {
            background-color: $bgSubmenuBg;
            color: black !important;
        }
        .bb_arrows {
            display: flex;
            background-color: transparent;
            justify-content: flex-end;
            align-items: center;
            z-index: 0;
            padding-right: 1rem;
            width: 100%; // height: 100%;
        }
        #lnk-home {
            background: white;
            width: 100%;

            a[data-depth="0"] {
                box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
                padding: 1rem;
                width: 100%;
                color: black;
            }
        }
        .bb_menu-list {
            flex-direction: column;
            order: 0;
        }
        .category {
            display: block;
            position: relative;
            background: white;
        }
        .dropdown-item,
        .bb_menu-secundarylabel {
            font-size: $menuMobileFontSize;
            line-height: $mobileMenuLineHeight;
            padding: 0 1rem;
            justify-content: flex-start;
            min-height: $menuMobileMinHeight;
            box-shadow: 0 -1px rgba(0, 0, 0, 0.08) inset;
            br {
                display: none;
            }
        }
        .bb_menu-secundarylabel {
            display: flex;
            align-items: center;
            box-shadow: none;
            margin-top: 7px;
            background-color: white; //box-shadow: 0 -1px rgba(0,0,0,0.08) inset;
           // background-color: $accentColor;
            color: black;
            margin-right: 0.5rem;
            color: black;
            &:active,
            &:hover {
                background-color: white;
                box-shadow: 0 -1px rgba(0, 0, 0, 0.08) inset;
                color: black;
            }
        }
        .bb_menu-secudary {
            padding: 0 0.5rem 0.5rem;
            justify-content: center;
            background-color: $primaryColor;
        }
        .dropdown-item {
            display: inline-flex;
            position: relative;
            width: auto; //min-width: 50%;
            width: calc(100% - 3.5rem);
            z-index: 1;
            padding: 1rem 0 1rem 1rem;
            box-shadow: none;
            align-items: center;
            .dropdown-submenu {
                box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08);
                background-color: #dcdcdc;               
                color: black;
            }
        }
        .dropdown-item.nochild {
            width: 100%;
        }
        .dropdown-item[data-depth="0"] {}
        .bb_menu-list[data-depth="1"] {
            margin-bottom: 0;
            background-color: $bgMobilefamilyBg;
            .category {
                box-shadow: 0 -1px 0 $colorShadow inset;
                background: #fafafa;
            }
            .dropdown-item {
                padding-left: 1.5rem;
            }
        }

        .bb_menu-list[data-depth="2"] {
            box-shadow: 0 -1px 0 $colorShadow inset;
            //background-color: $mobileSubmenuBg;
            
            margin-bottom: 0;
            .dropdown-item {
                padding-left: 2rem;
                width: 100%;
                background-color: $mobileSubmenuBg;
                box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08), 3px 0 rgba(0, 0, 0, 0.4) inset;
                color: black;
            }
        }
        .sub-menu {
            flex-direction: column;
            width: 100%;
            border-top: 1px solid $colorShadow;
        }


        .collapse {
            overflow: hidden;
            display: block;
            height: auto;
            max-height: 0;
            transition: max-height 0.4s ease-out;
        }


        [data-js-active]~.collapse {
            display: block;
        }

        input[id*="toggle_"]:checked~.collapse {
            transition: max-height 0.4s ease-in-out;
            max-height: 150rem;
        }
        .bb_arrows .material-icons {
            transition: transform 0.4s ease-in; //box-shadow: 0 -1px 0 rgba(0,0,0,0.2);
        }

        input[id*="toggle_"]:checked~.bb_arrows .material-icons {
            //transform:rotate(-180deg);
            transform: rotate3d(1, 0, 0, 180deg);
            transition: transform 0.4s ease-out;
        }
    }

    .bb_menu .dropdown-item::after {
        color: $primaryColor;
        &:hover{
            color: #fff !important;
        }
    }

    #header .bb_menu {
        padding: 0 !important;
    }

    #index .owl-top-banner .bn-top-content img {
        height: auto;
    }

    #cart .cart-grid-body {
        margin-bottom: 5rem;
    }
}

#toggle_bbmenu {
    display: none;
}


@media (min-width: 768px) and (max-width: 1110px) {
    .bb_menu-close {

        display: block;
    }

    body {
        padding-top: 5rem;
    }

    .bb_menu-burguer {
        display: flex;
        margin-left: 15px;
        padding-top: 15px;
    }

    #burg {
        display: flex;
    }

    #header {
        background-color: $bgSubmenuBg !important;
        z-index: 1;
        position: fixed !important;
        top: 0;
        width: 100%;
        z-index: 10;
        height: 9.1rem;
    }


    #header .bb_menu {
        position: relative;
        box-shadow: none;
        clear: both;
        display: block;
        max-width: 30rem !important;
        margin: 0;

        background-color: $bgSubmenuBg;
        transform: translateX(-35rem);
        transition: transform 0.4s ease-in-out;
        pointer-events: none;
        max-height: calc(100vh - 8rem);
        overflow-y: auto;
        box-shadow: $popOverBoxShadow;

        .bb_menu-list {
            background-color: $bgSubmenuBg;
        }

        #lnk-home {
            width: 100%;
            a[data-depth="0"] {
                padding: 0 0 0 1rem;
            }
        }
        .bb_menu-list {
            flex-direction: column;
            order: 0;
        }
        .category {
            display: block;
            position: relative;
            background: white;
        }
        .dropdown-item,
        .bb_menu-secundarylabel {
            font-size: $menuMobileFontSize;
            line-height: $mobileMenuLineHeight;
            padding: 0 1rem;
            justify-content: flex-start;

            min-height: $menuMobileMinHeight;

            br {
                display: none;
            }
        }
        .bb_menu-secundarylabel {
            //box-shadow: 0 -1px rgba(0,0,0,0.08) inset;
        }
        .dropdown-item[data-depth="0"] {}
        .bb_menu-list[data-depth="1"] {
            margin-bottom: 0;
        }
        .bb_menu-list[data-depth="2"] {
            box-shadow: none;
            border-left: none;
            margin-bottom: 0;
            .dropdown-item {
                padding-left: 2rem;
                width: 100%;
                //background-color: #fafafa;
                background-color: $mobileSubmenuBg;
                box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08), 3px 0 rgba(0, 0, 0, 0.4) inset;
                color: black;
            }
        }
        .sub-menu {
            flex-direction: column;
            width: 100%;
        }
        .dropdown-item.dropdown-submenu {}
        [data-js-active]~.collapse {
            display: block;
        }
    }

    #toggle_bbmenu:checked~.bb_menu {

        transform: translateX(0);
        pointer-events: auto !important;
    }
    input[id*="toggle_"]:checked~.bb_menu-close {
        opacity: 1;
        pointer-events: auto;
    }

    .ls-v5 .ls-bottom-slidebuttons {
        top: -25px !important;
    }

    #index {
        
        .container {
            padding: 0 !important;
        }

        .owl-top-banner {
            padding: 0 15px;
        }

        .out-prod-filter {
            padding: 0 15px;
        }

        .prod-filter-home {
            padding: 0 15px;
        }

        .owl-block-testimonial {
            padding: 0 15px;
        }
        
        .footer-container {
            padding: 0 15px;
            margin-top: 50px;
        }
    }

    #checkout #block-reassurance {
        ul {
            display: block !important;
            width: 100% !important;
            height: auto;
            li {
                max-width: 100% !important;
                display: block !important;  
            }

            .block-reassurance-item {
                padding: 0.5rem 0;
                display: table;
                .h6 {
                    width: calc(100% - 1.5rem);
                    vertical-align: middle;
                    display: table-cell;
                }
            }
        } 
    }

    #cart .cart-grid {
        padding-top: 100px;
    }

    .owl-prod-filter  {
        margin: 60px auto;
    }

    #new-products #wrapper {
        padding-top: 30px;
    }

    #best-sales #wrapper {
        padding-top: 30px;
    }

    #order-confirmation #wrapper {
        padding-top: 30px;
    }

    #my-account #wrapper {
        padding-top: 30px;
    }

}

@media (min-width: 1110px){
    #header .bb_menu .bb_menu-list[data-depth="0"]>.category>.dropdown-item:before{
        content: "\E313";
        direction: ltr;
        display: inline-block;
        font-family: "Material Icons";
        font-feature-settings: "liga";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        height: 1em;
        letter-spacing: normal;
        line-height: 1;
        overflow-wrap: normal;
        text-rendering: optimizelegibility;
        text-transform: none;
        vertical-align: middle;
        white-space: nowrap;
        width: 1.2em;
        opacity: .5;
        transform: translateY(-3px);
        transition: transform 0.3s,opacity 0.4s;
     }
     #header .bb_menu .bb_menu-list[data-depth="0"]>.category[data-js-active]:hover>.dropdown-item:before{
        opacity: 1;
        transform: translateY(2px);
     }

     .owl-slideshow .ls-bottom-nav-wrapper .ls-bottom-slidebuttons {
        top: -35px;
     }

     #header {
        z-index: inherit !important;
      }

      #header .header-nav {
        margin: 0 auto;
        max-width: 1300px;
      }

      #wrapper .breadcrumb {
        background-color: #fff;
      }

      #index .page-top {
          margin: 0 auto;
          max-width: 1300px;
          padding: 0 15px;
      }

      .owl-top-banner {
        margin: 0 auto;
        padding: 0 15px;
      }

      .owl-filter-tophome {
          margin-top: 120px !important;
          padding: 0 15px;
      }

      .owl-prod-filter {
          margin: 60px auto;
          max-width: 1300px;
          padding: 0 15px;

      }

      #index .container {
          max-width: 100% !important;
          padding: 0;
      }
      
      .out-block-testimonial {
          margin: 0 auto;
          max-width: 1300px;
          padding: 0 15px;
      }

      .footer-container {
          margin: 20px auto 0 auto;
          padding: 0 15px;
          max-width: 1300px;
      }

      .quick-view {
         // margin-left: 4px !important;
         // margin-right: 4px !important;
      }

      #cart .alert {
          margin-top: 25px;
      }

      #index .page-top {
        padding-top: 120px;
    }

    #index .footer-container {
        margin: 60px auto 0 auto;
    }

    #cart .cart-grid {
        padding-top: 40px;
    }

    #checkout #wrapper {
        margin-top: 0;
        padding-top: 40px;
    }

    #new-products #wrapper {
        padding-top: 30px;
    }

    #new-products #wrapper .breadcrumb-container {
        margin-bottom: 0;
    }

    #order-confirmation #wrapper .breadcrumb-container {
        margin-bottom: 0;
    }

    #my-account #wrapper .breadcrumb-container {
        margin-bottom: 0;
    }

    #my-account #wrapper {
        padding-top: 30px;
    }

    #order-confirmation #wrapper {
        padding-top: 30px;
    }

    #best-sales #wrapper {
        padding-top: 30px;
    }

}

@media (min-width: 1111px) {
    body {
        padding-top: 0;
    }
    #header .bb_menu {
        display: flex;
        width: 100%;
    }
}

@media (min-width: 1110px) and (max-width:1200px) {
    .quick-view {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media (max-width: 767px) {

    #toggle_bbmenu:checked~.bb_menu {
        display: block;
    }
    .ps_breadcumb+.container {
        padding-top: 1.5rem;
    }

    .header-default .header-nav a:hover {
        color: $accentColor;
    }
    .page-my-account #content .row {
        margin-left: 0px;
        margin-right: 0px;
        .links {
            display: block;
        }
    }

    #checkout #block-reassurance {
        display: block !important;
    }

    #checkout #block-reassurance ul .block-reassurance-item .h6 {
        text-align: left !important;
    }

    #_mobile_currency_selector {
        span {
            color: $accentColor !important;
        }

        select {
            color: #000;
        }
        
    }

    #_mobile_language_selector {
        .hidden-md-up {
            color: $accentColor !important;
        }
        .expand-more.hidden-sm-down {
            color: #000 !important;
        }

        span.expand-more.hidden-sm-down::after {
            content: "▼";
            margin-left: 5px;
        }
        .language-selector{
            background-color: #fff;
        }
    }

    #index #mobile_top_menu_wrapper {
        margin: 0 !important;
        padding: 0;
    }

    #header .header-top {
        padding-bottom: 0;
    }

    #mobile_top_menu_wrapper .js-top-menu-bottom #contact-link {
        padding: 0;
    }

    .block-contact .remove {
        font-size: 24px !important;
    }

    #index .ls-bottom-slidebuttons {
        top: -25px !important;
    }

    #index .owl-block-testimonial {
        margin-top: 20px !important;
        .clearfix {
           margin-bottom: 0 !important; 
        }   
    }

    #index .page-top {
        margin-top: 20px;
    }

    #index {
        .container {
            padding: 0;
        }

        .owl-top-banner {
            padding: 0 15px;
        }

        .owl-filter-tophome {
            padding: 0 15px;
        }

        .owl-prod-filter {
            padding: 0 15px;
        }

        .owl-block-testimonial {
            padding: 0 15px;
        }

        #block-reassurance {
            display: none;
        }
    }

    #footer {
        padding-top: 0;
    }

    #js-product-list .hidden-md-up {
        display: none;
    }

    #category #search_filter_toggler {
        width: auto;
        margin-left: 5px !important;
    }

    #category .pagination {
        a[rel="next"] {
            font-size: 0;
        }
        a[rel="prev"] {
            font-size: 0;
        }
    }

    #category .quickview .modal-body {
        min-height: auto;
    }

    #category .quickview .modal-content {
        min-height: auto;
    }

    #product #block-reassurance ul .block-reassurance-item .h6 {
        text-align: left;
    }

    body#product .col-md-8 {
        padding: 0 25px;
    }

    #product .tabs .tab-pane ul {
        padding: 0 25px;
    }
}

@media (min-width: 769px) and (max-width: 833px) {
    #header {
        .bbheader-searchcontainer {
            display: flex;
            justify-content: start;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}

@media (min-width: 768px) and (max-width: 975px) {
    #index .owl-filter-tophome .product-miniature .owl-label {
        left: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    body {
        padding-top: 7.9rem;
    }
    #header {

        #_desktop_logo {
            flex-grow: 0;
            flex-basis: auto;
            flex-shrink: 0;
        }
        .header-top {
            padding-bottom: 1.85rem;
        }
    }
    .bb_menu-burguer {
        display: block;
    }
}

#header .header-nav .header-mobile.mobile {

    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    >.clearfix {
        display: none;
    }
    .btn.canvas-menu {

        order: 5;
    }
    .top-logo {

        width: auto;
        flex-grow: 1;
    }
}

@media (min-width: 992px) and (max-width: 1110px) {
    #product #wrapper {
        margin-top: 80px;
    }
    
    #cart .alert {
        //margin-top: 60px;
    }

    #index .container {
        max-width: 100% !important;
        padding: 0 !important;
    }
}

@media (min-width: 1003px) and (max-width: 1110px) {
    #header {
        height: 5rem;
    }

    #header .header-top {
        padding-bottom: 0.25rem;
    }
 }

@media (max-width: 600px) {
    #exportDataToPdf {
        margin-top: 10px;
    }

    .social-sharing > span {
        display: inline-block;
    }
}


@media (max-width: 430px) {
    #header .header-nav .top-logo a img {
        max-height: 42px;
    }

    #header .header-nav #menu-icon {
        margin-left: 0;
    }

    #header .header-nav {
        padding: 0 5px;
    }

    #_mobile_cart {
        margin: 0 !important;
    }

    .footer-after {
        padding: 0 15px;
    }
}