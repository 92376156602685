.tags--product {
    align-content: space-around;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0.75rem 0 0;
    padding: 0.75rem 0;
}

.tags-brandlink {
    align-items: center;
    background-color: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin: 0;
    opacity: 0.9;
    position: relative;
    transition: box-shadow 0.1s ease 0s, opacity 0.1s ease 0s;
    width: 5.5rem;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.2);
        opacity: 1;
        outline: medium none;
    }
}

.tags-brandimg {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%; //opacity: 0.6;
    //transition: opacity 0.1s ease 0s;
}

.tags a.tags-link,
.bb-tag {
    font-family: $fontFamily;
    font-size: 1rem;
    font-style: normal;
    background-color: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    font-size: 0.8rem;
    margin: 0.25rem 0 0.25rem 0.5rem;
    padding: 0 0.75rem;
    text-transform: lowercase;
    color: black;
    display: flex;
    align-items: center;
    height: 2rem;
    line-height: 1;
    transition: background-color 0.2s ease 0s, box-shadow 0.1s ease 0s, color 0.1s ease 0s;
    &:hover,
    &:focus {
        background-color: #444;
        color: $tagsHoverColor;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
}

.bb-tag {
    display: inline-block;
    font-size: 0.75rem;
    height: auto;
    padding: 0.5rem;
    vertical-align: middle;
}

.product-manufacturer {

    display: inline;
    padding: 1.5rem 0.2rem 0.5rem 0;
    a {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}



.control-label,
.product-manufacturer>label {
    padding-right: 0.5rem;
}

.bb-productAtr {
    margin: 1rem 0;
    display: inline;
}