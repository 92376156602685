@include block(button) {
    &:hover,
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
    &:active {}
}

.btn-secondary, .btn-tertiary {
	//color: #1e1e1e;
	background-color: #f6f6f6;
	//border-color: transparent;
}
