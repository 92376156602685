#_mobile_language_selector {
    .expand-more.hidden-sm-down {
        display: inline-block !important;
        padding: 1rem;
    }
    .dropdown-menu.hidden-sm-down[style="display: block;"] {
       display: block !important;
      
    }
}



.language-selector .dropdown-menu {
    padding: 0;
    float: none;
    left: -1.5rem;
    right: auto;
}



.language-selector .ps_lang-container {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin: 0;
    padding: 0.25rem 0.5rem;

    li {
        font-size: 0.8rem;
    }


    @include addBreakpoint(md) {
        width: 30rem;
    }

    .dropdown-item {
        color: black;
        padding: 0.5rem 0.75rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        transition: color 0.2s;
        img {
            margin-right: 0.2rem;
        }
        &:hover,
        &:focus {
            color: $accentColor;
        }
    }


    .current .dropdown-item {

        color: $accentColor;
    }
}

.language-selector .ps_lang-container li {
    @include addBreakpoint(md) {
        width: calc(100% / 3);
    }
}