#product #block-reassurance{
    background: none;
    margin-bottom: 20px;
    justify-content: left;
    opacity: 0.6;
    span {
        font-size: 0.7rem;
    }
    ul{
        display: inline-block;
        height: auto;
        width: auto;
        .block-reassurance-item{
            flex-direction: initial;
            padding: 0.5rem;
            align-items: flex-start;
            img{
                background-color: none;
                width: 40px;
                height: 40px;
                padding: 0;
            }
            span{
                font-weight: 600;
            }
        }
        li{
            display: inline-block;
        }
    }
}

#checkout #block-reassurance{
    background: none;
    margin-bottom: 10px;
    ul {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        width: auto;
        .block-reassurance-item{
            flex-direction: initial;
            padding: 0.5rem 0;
            align-items: flex-start;
            img{
                background-color: none;
                width: 50px;
                height: 50px;
                padding: 0;
            }
            span{
                font-weight: 600;
                text-align: left;
            }
        }
        li{
            align-items: start;
            flex-basis: 50%;
            max-width: 50%;
            display: flex;
        }
    }
}


#cart #block-reassurance{
    background: none;
    margin-bottom: 10px;
    ul {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        width: auto;
        .block-reassurance-item{
            flex-direction: initial;
            padding: 0.5rem 0;
            align-items: flex-start;
            img{
                background-color: none;
                width: 50px;
                height: 50px;
                padding: 0;
            }
            span{
                font-weight: 600;
                text-align: left;
            }
        }
        li{
            align-items: start;
            flex-basis: 50%;
            max-width: 50%;
            display: flex;
        }
    }
}

#block-reassurance{
    //background: lighten($primaryColor, 50%);
    background: rgba($primaryColor, .1);
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    ul{
        height: 220px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 80%;
        .block-reassurance-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                background-color: white;
                height: initial;
                margin: 0;
                opacity: 1;
                padding: 10px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
            span{
                margin-top: 15px;
            }
            .h6{
                text-align: center;
                width: 100%;
            }
        }
    }
}

#index #block-reassurance {
    margin-top: 80px;
}