@include block(block_newsletter) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include element(subtitle) {
        display: block;
        color: inherit;
        opacity: 0.8;
        line-height: 1;
        width: calc(100% - 10rem);
    }

    @include element(content) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        flex-grow: 0;
        justify-content: space-between;
        width: calc(100% - 30rem);
    }

    @include element(label) {
        display: none;
    }

}

.footer-before .row {
    margin: 0;
    padding: 0;
    display: block;

        &:after{
            display: none;
        }
    
}

.footer-before .block_newsletter>.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    .col-md-6.col-lg-5.col-xs-12{
        padding-bottom: 1rem;

    }
  

    .col-md-6.col-lg-7.col-xs-12 {
      
        float: none;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        padding-left: 1rem;
        width: auto;
        flex-direction:column;

        h2:before{
            display: none;
             @include addBreakpoint(sm) {
                display: inline-block;

             }

        }
        span {            
            float: none;
            margin: 0; 
            line-height: 1;
            display: inline-block;           
            @include addBreakpoint(sm) {
               padding-left: 2.5rem;
                
            }
        }
    }
}



#mc_embed_signup {
    margin-left: auto;
    margin-right: -9rem;
    z-index: 1;
    max-width: 100%;
}

#mc_embed_signup_scroll {
    display: flex;
    justify-content: flex-end;
}

#mc_embed_signup div[aria-hidden] {
    display: none;
}

.block_newsletter .block_newsletter-title {
    float: none;
    margin: 0;
}

.block_newsletter .block_newsletter-subtitle {
    padding: 0;
    padding-left: 2.75rem;
    margin: 0;
    margin-top: 0.25rem;
}

.block_newsletter .block_newsletter-button {
    @extend .btn;
    @extend .btn-primary;
    display: flex;
    height: 37px;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 9rem;
    float: none;
    z-index: 0;
}

.block_newsletter .block_newsletter-placeholder {
    margin: 0;
    position: relative;
    top: -1em;
}

.footer-before .block_newsletter form {
    margin: 0;
}

.block_newsletter span.block_newsletter-button {
    border: 3px solid #fff;
}

.block_newsletter .clear {
    .button {
        @extend .btn;
        @extend .btn-primary;
        border: 3px solid;
        font-size: 0;
        height: 37px;
        width: 9rem;
        opacity: 0;
        outline: none;
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
}

.block_newsletter form input {
    border: none;
}

.block_newsletter {
    margin: 0;
    padding: 0;
    position: relative;
}

.block_newsletter form input[type="email"] {
    padding: 0.5rem 1rem;
    color: black;
    width: 19rem;
    &:hover {
        color: black;
    }
}

.block-newsletter-content #checkbut:disabled {
    background-color: lighten( $newsletterinputenabledBackground, 20%);
    color: #000;
}

.block-newsletter-content #checkbut:enabled {
    background-color: darken( $newsletterinputenabledBackground, 20%);
    color: #fff;
}

@media (max-width: 1200px) {

    .block_newsletter {
        justify-content: center;

        .block_newsletter-label {
            display: flex;
            margin: 0;
            padding: 0.2rem 0rem 0.2rem 0.5rem;
        }

        .block_newsletter-placeholder {
            display: none;
        }

        .block_newsletter-content {
            width: 100%;
            padding-bottom: 2rem;
        }

        .block_newsletter-subtitle {
            width: 100%;
            margin: 0.5rem 0;
            padding: 0;
            text-align: center;
        }

        .block_newsletter-title {
            width: 100%;
            text-align: center;
        }

        span.block_newsletter-button {
            margin-left: -9rem;
        }

        #mc_embed_signup {
            width: auto;
            padding: 0;
            margin: 0;
        }
    }
}

@media (max-width: 767px) {

    .block_newsletter {
        form input[type="email"] {
            max-width: calc(100% - 4rem);
        }
        .block_newsletter-content {
            padding-bottom: 1rem;
        }
    }
}

@media (max-width: 640px) {

    .block_newsletter {
        span.block_newsletter-button {
            font-size: 0;
            padding: 0;
            position: relative;
            height: auto;
            width: auto;
            margin: 0;
            margin-left: -3rem;
            margin-top: -3px;
            &:before {
                font-size: 1rem;
                content: "OK";
                padding: 0.15rem 0.6rem;
            }
        }

        .clear .button {
            height: 2.5rem;
            width: 3rem;
        }
    }

    .block_newsletter #mc_embed_signup {
        width: auto;
        padding: 0;
        margin: 0;
        max-width: 100%;
    }
}

@media (max-width: 480px) {

    .block_newsletter .block_newsletter-label {
        display: block;
        margin: 0;
        padding: 0.2rem 0 0.5rem;
        width: 100%;
        text-align: center;
    }

    .footer-before .block_newsletter h2:before {
        content: "";
    }
}

.block_newsletter input[type="email"]::-webkit-input-placeholder {
    color: white;
}

.block_newsletter input[type="email"]::-moz-placeholder {
    color: white;
}

.block_newsletter input[type="email"]:-ms-input-placeholder {
    color: white;
}

.block_newsletter input[type="email"]:-moz-placeholder {
    color: white;
}