#product-modal .modal-content .modal-body .product-images {
    margin-left: 0.5rem;
    width: 6rem;
}
.product-description p {
    line-height: 1.4;
}

#product .product-add-to-cart .btn:disabled {
    background-color: rgba(80, 80, 80, 0.2) !important;
    cursor: not-allowed;
    pointer-events: visible;
    color: #969696;
    &:hover {
        color: #969696 !important;
    }
}

.modal-content .product-add-to-cart .btn:disabled {
    background-color: rgba(80, 80, 80, 0.2) !important;
    cursor: not-allowed;
    pointer-events: visible;
    color: #969696;
    &:hover {
        color: #969696 !important;
    }
}

.product-quantity .qty {
    width: 75px;
    margin-right: 10px;
}

#product .product-category-content .product-miniature {
    padding: 0 15px;
}
 
#product .product-container-img {
    .quick-view {
       // top: 275px;
       // left: 15px;
       // width: 280px
       bottom: 0;
    }
    .product_img_link {
       img {
        width: 100%;
        } 
    }
    
    @media (max-width:1400px) {
        .quick-view {
            left: 0;
        }
    }
    @media (max-width:1200px) {
        .quick-view {
            left: -30px;
            top: 220px;
            width: 280px;   
        }
    }
    @media (max-width:1110px) {
        .quick-view {
            display: none;
        }
       
    }
}

#product-modal .modal-content .modal-body .image-caption {

    border: none;
    padding: 0;

    #product-description-short {
        color: #1e1e1e;
        border-top: 1px solid #ebebeb;
        padding: 0.625rem 1.25rem;
        font-size: 0.9rem;
        width: 100%;
    }

    #product-description-short:empty {
        display: none;
        
    }
}

.product-quantity .input-group-btn-vertical .btn {
    i{
        color: #1e1e1e;
    }
    &:hover {
        i{
            color: #ebebeb;
        }
    }
}

#product-modal .modal-content .modal-body .mask {
	
	max-height: 45.5rem;
	
}

#product-modal .modal-content .modal-body .arrows .arrow-up {
	
	top: -2rem;
	left:2rem;
	
}
#product-modal .modal-content .modal-body .arrows .arrow-down {
	
	bottom: -1rem;
	left: 2rem;
}
@media (max-width:991px) {
	#product-modal .modal-content .modal-body .product-images {
	display: none;
	
}
	
}




