@import "ibizaextras";

.btn-primary {
    background-color: $primaryColor !important;
    &:hover{
        background-color: $primaryColor !important;
    }
}

.btn-secondary{
    background: #f6f6f6 !important;
    color: $primaryColor !important;
    &:hover{
        background-color: $primaryColor !important;
    }
}

.btn-tertiary{
    background: rgba($primaryColor, .3) !important;
    color: $primaryColor;
    &:hover{
        background-color: $primaryColor !important;
    }
}

#authentication .custom-checkbox span {
    flex: 0 0 0.9rem;
    margin-top: 3px;
}

#identity .custom-checkbox{
    label {
       display: inline; 
    }
    span{
        margin-top: -3px;
    }
}

#cart .js-touchspin {
    &:hover {
        background-color: rgba($primaryColor, .3) !important;
        i {
            color: #fff !important;
        }
    }
    i {
     color: #000 !important;   
    }
}

.block-newsletter-content .hidden-sm-up {
    background-color: darken( $primaryColor, 20%) !important;
    color: #fff !important;
}

#checkout .custom-checkbox span {
    flex: 0 0 13px !important;
    margin-left: 3px;
}

#payment-confirmation .btn:disabled {
    //background-color: rgba( $primaryColor, .1) !important;
    background-color: rgba(80,80,80,0.2) !important;
    color: #969696;
    cursor: not-allowed;
    pointer-events: visible;
    &:hover {
        color: #969696 !important;
    }
}

