.pagination {
    align-items: center;
    border-top: 1px solid $colorShadow;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding-top: 1rem;

    .custom-select {
        padding: 0 1.75rem 0 0.75rem;
        margin-top: 0.5rem;
    }
    .pagination>div:first-child {
        float: none;
        margin-bottom: 0.25rem;
    }

    .page-list {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        padding: 0.625rem 0;
        flex-wrap:wrap;
        background-color: $wrapperColor;

        li {
            display: block;
            margin-left: 0.5rem;
            margin-bottom:0.5rem;
        }

        span.spacer {
            display: block;
            font-size: 1.5rem;
            height: 2rem;
            font-weight: lighter;
        }



        a {
            display: block;
            line-height: 1;
            box-shadow: 0 0 0 1px $color-shadow inset;
            padding: 0.75rem 1rem;
            transition: background-color 0.2s, color 0.2s;
            .material-icons {
                font-size: 1rem;
                height: 1rem;
            }
            &:hover,
            &:focus {
                @include text-contrast($accentColor);
                background-color: $accentColor;
            }
        }
        a.disabled {
            pointer-events: none;
        }
    }
}


.pagination-nav {
    float: none;
}


.pagination .sort-select{
    order:3;

}

.pagination .custom-select {
    cursor: pointer;
    margin-top: 0;
    .material-icons {
        display: none;
    }
}



.pagination .dropdown-menu{
        right: 0;
        left:auto;
        text-align: center;
        min-width: 3.75rem;
        padding: 0;
        margin-top: 0.25rem;

       a{
        padding: 0;
        //background-color: cyan;
       }

}