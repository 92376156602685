///////////Back to top 

#to_top{
    display: block;
    position: fixed;
    bottom: 3.84615rem;
    width: 2.4rem;
    height: 2.4rem;
    color: #fff !important;
    text-align: center;
    padding: 0.38462rem;
    right: 15px;
    cursor: pointer;
    z-index: 100;
    background: lighten($accentColor, 10%);
    transition: all 1s ease;
    &:hover{
        background-color: $accentColor;
    }
}

#to_top span{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    line-height: 30px;
    font-size: 35px;
}

.cd-top{
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-is-visible{
    visibility: visible;
    opacity: 1;
}

//////////

img.ls-bg.ls-preloaded {
    margin-top: 0 !important;
}


.ls-l.small_text {
    background-color: $accentColor;
    border-color: $accentColor;
    @include text-contrast($accentColor);
    transition: background-color 0.2s, color 0.2s;
    &:hover {
        background: $primaryColor;
        border-color: $primaryColor;
        @include text-contrast($primaryColor);
    }
}

div.owl-bock-home {
    background-color: $accentColor;
    @include text-contrast($accentColor);
    >a {
        color: inherit;
    }
}

.ls-l.big_yellow {
    background-color: $accentColor;
    @include text-contrast($accentColor);
}

#_desktop_cart i.shopping-cart,
#_mobile_cart i.shopping-cart {
    background-color: $accentColor;
    @include text-contrast($accentColor);
}

.out-block-testimonial div.items-aline {
    background-color: $footerBg;
    @include text-contrast($footerBg);
}

div.owl-out-title>h3,
.post_title a {
    color: $primaryColor;
}

div.g-blog-info>div.read-more {
    border-color: $accentColor;
    &:hover,
    &:focus,
    &:active {
        background-color: $linkHoverColor;
        @include text-contrast($linkHoverColor);
        >a {
            color: inherit;
        }
    }
}

a:hover,
.dropdown-item:focus,
.dropdown-item:hover {
    color: $linkHoverColor;
}











#cookie_notice .notice-cookie-right button {
    background-color: $accentColor;
    border-color: $accentColor;
    @include text-contrast($accentColor);
    &:hover {
        color: black;
    }
}

#cookie_notice .notice-cookie-right button.no-thank:hover {
    background-color: $linkHoverColor;
    border-color: $linkHoverColor;
    @include text-contrast($linkHoverColor);
}

div#cookie_notice .notice-cookie-right>span {
    color: $accentColor;
}

.post_title:hover,
.post_title a:hover {
    color: $accentColor;
}


.owl-top-banner .bn-top-content>a.toge-link {
    background-color: rgba(0, 0, 0, 0.26);
    transition: background-color 0.2s;
    top: 0;
    bottom: inherit;
    background: #f4f3f0;
    color: black;
    opacity: 0.5;
    transition: opacity 0.5s ease;

}

.owl-top-banner .bn-top-content:hover>a.toge-link {
    background-color: $linkHoverColor;
    @include text-contrast($linkHoverColor);
    transition: opacity 0.5s ease;
    opacity: 1;
}

.owl-block-testimonial {
    margin-top: 100px !important;
    margin-bottom: 30px !important;
}

.quick-view {
    background: $primaryColor;
    top: inherit;
    left: 0;
    right: 0;
   // margin-top: -2rem;
    margin-left: inherit;
    width: inherit;
    @include text-contrast($primaryColor);
    &:hover {
        background: $linkHoverColor;
        @include text-contrast($linkHoverColor);
    }
    i{
        font-size: 25px;
    }
}

.block-categories a.h6,
#search_filters>h4.h6,
#search_filters .facet .facet-title,
.price {
    color: $primaryColor;
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
    border-color: $primaryColor;
}

.btn-secondary:hover,
.btn-tertiary:hover,
.pagination .current a {
    background-color: $primaryColor;
    border-color: $primaryColor;
    @include text-contrast($primaryColor);
}

.product-actions .add-to-cart {
    background-color: $linkHoverColor;
    border-color: $linkHoverColor;
    @include text-contrast($linkHoverColor);
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.focus:active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open>.btn-primary.dropdown-toggle:hover,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle.focus {
    background-color: $linkHoverColor;
    border-color: $linkHoverColor;
    @include text-contrast($linkHoverColor);
}

.product-images>li.thumb-container>.thumb.selected,
.product-images>li.thumb-container>.thumb:hover {
    border-color: $linkHoverColor;
}



.header-top .search-widget form {
    margin: 0.5rem 1rem 1rem;
    @include addBreakpoint(md) {
        margin: 0 auto;
    }
}

.search-widget form input[type="text"] {
    background: white;
    border: 1px solid $colorShadow;
    color: black;
    min-width: 230px;
    font-size: 0.75rem;
}


.search-widget button[type="submit"] {
    background-color: white;
    border: medium none;
    color: #999;
    border: none;
    height: 35px;
    top: 3px;
    right: 20px;

    @include addBreakpoint(md) {
        right: 1px;
    }
}

#blockcart-modal .modal-header {
    background-color: $menuBg;
    @include text-contrast($menuBg);
}

#product-availability {
    color: #000;
    .product-unavailable {
        color: crimson;
        vertical-align: top;
    }
}

.btn-secondary,
.btn-tertiary {
    color: $primaryColor;
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-tertiary:hover {
    border-color: $primaryColor;
    @include text-contrast($primaryColor);
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
    background-color: $primaryColor;
    border-color: $primaryColor;
    @include text-contrast($primaryColor);
}

.btn-primary {
    background-color: $accentColor !important;
    border-color: $accentColor;
    @include text-contrast($accentColor);
    &:hover,
    &:focus {
        background-color: $primaryColor;
        border-color: $primaryColor;
        @include text-contrast($primaryColor);
    }
}

.product-line-grid .value,
.cart-grid-body .card-block h1,
.cart-summary-line .value,
.product-line-grid-right .cart-line-product-actions,
.product-line-grid-right .product-price {
    color: $primaryColor;
}

.contact-add {
    padding-left: 1.25rem;
    text-indent: -1.4rem;
    line-height: 1.2;
    padding-bottom: 0.2rem;
    .material-icons {
        left: 1.5rem;
        margin-right: 0;
        position: relative;
    }
}

.instagram {
    background-size: 1.5rem auto;
    background-image: none;
}

.instagram a{
      &:before{
        content: "\F16d";
        font-family: "FontAwesome";
      }
}



.ls-l.big_bluee {
    color: $primaryColor;
}

.ls-l.small_text.bg-white:hover {
    background: $primaryColor;
    border-color: $primaryColor;
    @include text-contrast($primaryColor);
}

.payment>li>a>img {
    display: block;
    overflow: hidden;
    border-radius: 4px;
}

#_desktop_cart .owl-cart p,
#_mobile_cart .owl-cart p {
    color: $headerItemsColor;
}

.left-nav .left-nav-content {
    background: transparent;
}


.thumbnail-container,
#search .thumbnail-container {

    border-color: transparent;
}

.thumbnail-container:hover {
    border-color: transparent;
}


#home .thumbnail-container {
    border-color: rgba(0, 0, 0, 0.05);
}

.dropdown-menu {
    font-size: 0.75rem;
}


.left-nav .dropdown .expand-more,
.dropdown .expand-more {
    color: $headerItemsColor;
    font-size: 0.75rem;
}



#header .header-nav .user-info {
    margin-left: 0.5rem;
    &:before {
        color: $headerItemsColor; //opacity: 0.5;
        padding-right: 0.5rem;
    }
    a {
        // @include text-contrast($headerBg);
        color: $headerItemsColor;
        font-size: 0.75rem; //opacity: 0.65;
    }
}



#wrapper {
    background-color: $wrapperColor;
    margin-bottom: 0;
    padding-bottom: 50px;
}

#category .thumbnail-container {
    border: none;
}

.block-category {
    box-shadow: 0 0 0 1px $colorShadow;
    background-color: #fff;
}

#search_filters .facet .facet-label a {
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.5rem;
}

#search_filters .facet .facet-label.active a,
#search_filters .facet .facet-label.active span i {
    color: $accentColor;
}


.category-sub-menu li[data-depth="0"] .category-sub-menu {
    //background-color: transparentize(black, 0.97);
}

.block-categories a:hover,
#wrapper .breadcrumb li a:hover,
.tabs .nav-tabs .nav-link.active {

    color: $accentColor;
}


.block-categories .category-sub-menu li:not([data-depth="0"]) {
    margin: 0;
    padding: 0;

    a {
        padding: 0.875rem 0 0.875rem 0.875rem;
        display: block; //background-color: transparentize(black, 0.97);
        //border-bottom: 1px solid #ebebeb;
        &:hover {
            //background-color: white;
        }
    }
}

#cart .cart-item {
    border-bottom: 1px solid #ebebeb;
    &:last-of-type {
        border-bottom: none;
    }
}

#order-confirmation #registration-form {
    width: 100%;
    .custom-radio {
        margin-right: 0.35rem;
    }

    .custom-checkbox {
        display: flex;
        input[name="newsletter"] + span{
            width: 15px;
        }
        span {
            width: 25px;
        }
    }
} 

#products .products-selection {

    margin: 0 0 1.25rem;
    background-color: $backgroundSecundary;
}

#cart #wrapper .breadcrumb-container {
    margin-bottom: 0;
}

#best-sales #wrapper .breadcrumb-container {
    margin-bottom: 0;
}

.page-title {
    color: $accentColor;
    font-size: 1.5rem;
}

.tabs .nav-tabs .nav-link.active::before {
    background: $accentColor;
}



.bbheader-searchcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.order-confirmation-table .order-line {
    padding: 10px 0;
}

//------------------------------------------------------------





//---------------------------



#header .header-nav .right-nav {

    align-items: center;
}



#_desktop_logo {
    flex-grow: 0;
    flex-basis: 235px;
    flex-shrink: 0;
}

#_desktop_logo>a {
    width: 100%;
    height: 100%;
    display: block;
}

#header .logo {
    max-width: 100%;
    height:auto;
    width: auto;
}



#header .header-nav {
    margin-bottom: 0;
}

.dropdown:hover .expand-more {
    color: $accentColor;
}



.owl-top-banner .bn-top-content {

    box-shadow: 0 0 0 1px $colorShadow;
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next {
    background: #444;
}


.owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.owl-carousel .owl-controls .owl-nav .owl-next:hover {
    background: $accentColor;
}

.product-title a {
    color: #1e1e1e;
    font-size: 0.9375rem;
    font-weight: 500;
}

.product-title a {

    display: block;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 0 0.5rem;
    height: 3.75em;



    &:hover {
        color: $accentColor;
    }
}

.owl-carousel .owl-item img {
   // height: 250px;
    background-color: $colorShadow;
}

.out-block-testimonial {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    &:after {
        display: none;
    }
    img {
        max-width: 80%;
    }
}

.out-block-testimonial>.bkg-testimonial.col-md-6 {
    float: none;
    padding: 0;
    width: 100%;
    @include addBreakpoint(md) {
        width: calc(50% - 0.75rem);
    }

    margin-bottom: 1.5rem;
}



@media screen and (-webkit-min-device-pixel-ratio:0) {


    .product-quantity .input-group-btn-vertical {
        position: relative;
        left: -7px;
    }
}

.aeuc_cart_custom_text {
    display: block;
    font-size: 0.9rem;
    padding: 0.75rem;
}


#main .page-header h1 {
    color: $accentColor;
}

.input-group .input-group-btn>.btn {
    background-color: #666;
}

.block_newsletter form input[type="text"]::-moz-placeholder {
    color: #000000;
    text-indent: -0.625rem;
}

.block_newsletter form input[type="text"]::-webkit-input-placeholder {
    color: #000000;
}



.owl-label {
    left: 0;
}

body#checkout section.checkout-step.-current .step-title,
body#checkout section.checkout-step.-complete .step-title {
    color: $accentColor;
}

body#checkout a:hover {
    color: $accentColor;
}

.custom-radio input[type="radio"]:checked+span {
    background-color: $accentColor;
}

.form-control:focus {

    outline: 0.0625rem solid $accentColor;
}

.input-group.focus {
    outline: 0.1875rem solid $accentColor;
}

.done {
    color: $accentColor;
}





.social-sharing {
    ul {
        margin-bottom: 0;
        margin-left: 0.5rem;
        display: flex;
    }
    li {

        border-radius: 6rem;
        height: 2.25rem;
        line-height: 2.22rem;
        margin-left: 0.5rem;
        width: 2.25rem;
    }
}





.product-container-img{
    border: 1px solid transparent;
}





.category-tree {
    padding: 0 1.5rem 1.5rem;
    @media (max-width: $desktopBreakpoint) {
        padding-top: 1.5rem;
    } //padding: 3.75rem 1.5rem  1.5rem 1.5rem;
}



.block-contact.col-md-3.links.wrapper {
    @media (max-width: $desktopBreakpoint) {
        margin: 0 auto;
        width: calc(100% - 30px);
        width: 100%;
    }
}




#products .products {


    margin: 0;
}

#products .product-miniature,
.featured-products .product-miniature {
    clear: both;
    float: none;
    margin: 0 auto;
    display: block;
}


#products .up .btn-secondary,
#products .up .btn-tertiary {
    @include text-contrast($headerBg);


    &:hover {
        @include text-contrast($accentColor);
    }
}

body.tax-display-enabled #products .thumbnail-container,
body.tax-display-enabled .featured-products .thumbnail-container,
body.tax-display-enabled .product-accessories .thumbnail-container {
    height: auto;
}

.prod-content-left {
    padding: 0;
    width: 100%;
}



.tabs .nav-tabs .nav-link:hover {

    color: $accentColor;
    &:before {
        background-color: $accentColor;
    }
}



body#checkout section.checkout-step .address-item.selected {

    border-color: $accentColor;
}


.cart-summary-line small.value {

    font-size: 0.8125rem;
    font-weight: 400;
}

.btn{
    background: darken($accentColor, 40%);
    color: white;
    margin-left: 0px !important;
    &:hover{
        background: darken($accentColor, 20%) !important;
        color: white !important;
    }
}

.btn.disabled,
.btn.disabled:hover {
    background: lighten( $accentColor, 10%);
    border-color: transparent;
    color: $colorTxt;
}

.btn.disabled,
.btn:disabled {
    opacity: 1;
    background: lighten( $accentColor, 10%);
    pointer-events: none;
    border-color: transparent;
}

.page-my-account #content .links li {
    list-style: none;
}

.page-my-account #content .links a:hover i {
    color: $accentColor;
}

.table-labeled .label {
    padding: 0.5rem 1rem;
}

.table-labeled .label[style*="background-color:#4169E1"] {
    background-color: mediumturquoise !important;
}

body#checkout section.checkout-step .payment-options label {

    text-align: left;
}



.owl-reassurance {
    padding-top: 0;
}



body#checkout #header .header-nav {
    box-shadow: none;
    padding: 0;
    padding-top: 0.625rem;
}



#product .tabs {
    padding-top: 0;
}

#authentication #main .page-header {
    padding-top: 1.5rem;
    text-align: center;
}

#authentication .breadcrumb-container {
    display: none;
}

#index .product-container-img {
    .product_img_link img {
       // margin: 1.5rem 15px;
       margin: 0;
    }
    .quick-view {
       // left: 11px;
       // width: 250px;
        bottom: 0;
    }
    @media (max-width: 1110px) {
        .quick-view {
            display: none;
        }
    }
}




#header .header-nav #menu-icon .material-icons {
    @include text-contrast($headerBg);
}



#index div.owl-bock-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    h2,
    h3,
    a {
        font-weight: lighter;
        padding: 0.75rem 1rem;
    }

    h2 {
        font-size: 32px;

        opacity: 0.5;
    }

    h3 {

        opacity: 0.8;
        text-transform: none;
        margin: 0;
    }
}



.owl-label .new-label {
    border: 1px solid $accentColor;
    background: $accentColor;
    display: inline-block;
    color: white;
    text-transform: uppercase;
    font-size: 0.7375rem;
    line-height: 2.3125rem;
}

#index .owl-out-title {
    margin-bottom: 3rem;
    &:after {
        border-bottom: 2px solid $backgroundSecundary;
        bottom: 0;
        top: inherit;
    }
    h3{
        color: $colorTxt;
        padding: 8px;
        font-weight: normal;
        background: $backgroundSecundary;
    }
}

.highlighted-informations {
    background-color: rgba(255, 255, 255, 0.3);
    margin-bottom: -10rem;
    min-height: 100%;
    pointer-events: none;
}


#product .tabs .nav-tabs {
    border-color: rgba(80, 129, 134, 0.32);
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

body {
    background-color: $bodyBg !important;
}


body#product {
    .page-title {
        font-weight: 400;
    }

    .product-prices .price {
        font-size: 2rem;
        font-weight: bold;
    }
}

.js-mailalert {
    font-size: 0.75rem;
    padding: 1.25rem 0 1rem;
    input {
        border: 1px solid $colorShadow;
        font-size: 0.75rem;
        padding: 1rem;
    }
}



.js-mailalert {
    display: flex;
    flex-wrap: wrap;
    input {
        font-size: 0.75rem;
        margin: 0 0.5rem 0.5rem 0
    }
    a {
        @include text-contrast($accentColor);
        background-color: $accentColor;
        padding: 1rem;
        transition: background-color 0.2s, color 0.2s;
        margin-bottom: 0.5rem;
        &:hover,
        &:focus {
            @include text-contrast(#ddd);
            background-color: #ddd;
        }
    }
}

@media screen and(max-width: 520px) {
    .js-mailalert a,
    .js-mailalert input {
        margin-right: 0;
        width: 100%;
    }
}

.product-prices .tax-shipping-delivery-label {
    display: inline-block;
    margin: 0;
    padding: 0.5rem;
    vertical-align: top;
}


body#product .col-md-8 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .social-sharing {
        order: 4;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }
    .bb_brand_container {
        order: 5;
    }
}

#product .social-sharing {
    .icon-gray {
        transition: background-color 0.2s;
        background-color: #ddd;
    }

    .facebook {
        &:hover {
            background-color: #3b5998;
        }
    }
    .twitter {
        &:hover {
            background-color: #1da1f2;
        }
    }
    .googleplus {
        &:hover {
            background-color: #dc4e41;
        }
    }
    .pinterest {
        &:hover {
            background-color: #cb2027;
        }
    }
}

#search #products .product-miniature {
    margin-bottom: 0.5rem;
    .thumbnail-container {
        height: auto;
    }
    .thumbnail-container {
        margin-bottom: 0;
        position: relative;
    }
}

#pagenotfound .page-not-found {
    padding: 1rem 2rem 1rem 1.5rem;
}


.cart-grid-body a.label:hover {
    color: $accentColor;
}


@media screen and(max-width: $mobileBreakpoint) {


    #header .header-top .position-static {
        position: static;
        width: 100%;
        float: none;
        padding: 0;
        margin: 0;
    }

    #_mobile_search_widget {
        display: block;
        float: none;
        margin: 1.5rem;
    }

    /*#_desktop_logo{
        outline: 1px solid red;

    }*/
}

@media screen and(min-width: $mobileBreakpoint) {

    #_mobile_search_widget {

        display: none;
    }

    /*#_mobile_search_widget {
        display: none;
    }

    /*#_desktop_search_widget {
        display: block;
    }*/
    #_desktop_search_widget {
    margin: 0 1rem;
}
}

.copy-right img{
    height: auto;

}



#index #mobile_top_menu_wrapper {
    border: none;
    margin: 0 auto 1rem;
}



.product-category-content {
    display: flex;
    flex-wrap: wrap;
    .product-miniature {


        width: 100%;

        .h3.product-title {
            width: calc(100% - 3.25rem);
            margin: 0 auto;
            word-wrap: break-word;
            display: inline-block;
        }
    }
}

@media screen and(min-width: 370px) {
    .product-category-content .product-miniature {
        width: calc(100% / 2);
    }
}



@media screen and(min-width: 620px) {
    .product-category-content .product-miniature {
        width: calc(100% / 3);
    }
}



@media screen and(min-width: 780px) {
    .product-category-content .product-miniature {
        width: calc(100% / 3);
    }
}




@media screen and(min-width: $desktopBreakpoint) {
    .product-category-content .product-miniature {
        width: calc(100% / 4);
    }
}






.products-sort-order .select-list:hover {
    background: $accentColor;
    
}