#manufacturer {

    .block-category {
        min-height: 0;
    }


    section#wrapper {
        //border-top: 1px solid $colorShadow;
        margin-top: 1px;
    }
    #content-wrapper {
        position: relative;
        //overflow: hidden;
        ul {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 3rem);
            width: auto;
        }
    }


    .container {
        max-width: 1200px;
        padding: 0 1.5rem;
        @include addBreakpoint(xl) {
            padding: 0;
        }
    }

    .footer-container  .container{
        padding-top: 3rem;
    }
    #header .header-top {
        background-color: transparent;
        border-bottom: 1px solid white;
    }

    
    .btn-secondary, .btn-tertiary{
        background-color: grey;
    }
}



.brand {
    margin-right: 1.5rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2) inset;
    text-align: center;
    margin-top: 1.5rem;
    position: relative;
    width: calc(100% - 3rem);
    @media (min-width: 540px) {
        width: calc(100% / 2 - 2.25rem);
    }
    @media (min-width: $mobileBreakpoint) {
        width: calc(100% / 3 - 2rem);
    }
    @media (min-width: 990px) {
        width: calc(25% - 1.9rem);
    }
    @media (min-width: 1240px) {
        width: calc(20% - 1.8rem);
    }
    &:after {
        box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1) inset;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        transition: opacity 0.2s;
        opacity: 0;
        pointer-events: none;
    }
    &:hover {
        &:after {
            opacity: 1;
        }
    }
    .brand-products {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        line-height: 1.2;
        padding: 0.25rem 0 1rem;
    }
    .brand-infos {
        h3,
        a {
            width: 100%;
            color: $accentColor;
            letter-spacing: 0;
            padding: 0 0.5rem;
        }
    }
    .brand-img>a {
        display: block;
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.6;
        }
    }
}