#header {
    @include text-contrast($headerBg);
    border-bottom: $headerBorderBottom;
    background-color: $headerBackgroundColor;
    background-image: $headerImage;
}

#header .header-top {
  background: transparent;
}
#mobile_top_menu_wrapper {
  background: transparent;
}

#header .header-nav .material-icons {
  color: $primaryColor;
}





 
