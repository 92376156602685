$colsWidth: 13.5rem;



@media (min-width: $desktopBreakpoint) {
    .category-label,
    .bb_menu-images-container {
        display: block;
    }
    #header .bb_menu #lnk-home {
        flex-grow: 0.25;
        a[data-depth="0"] {
            justify-content: center;
            padding: 0 0.5rem;
        }
        &:hover ~.category .popover.sub-menu.js-sub-menu.collapse {
            display: none !important;
        }
    }
    #header .bb_menu {
        position: relative;
        z-index: 10;
        top: $menuDesktopTop;
        .bb_menu-secudary {
            justify-content: center;
            flex-direction: row;
        }
        .dropdown-item {
            position: relative;
           
            //padding: 0.4rem 0.5rem 0.4rem 0.75rem;
        }
        .bb_menu-popover {
            @include text-contrast($bgSubmenuBg);
            position: absolute;
            left: 0;
            background-color: $bgSubmenuBg; 
            height: auto;
            min-height: $subMenuHeight;
            margin: 0;
            min-width: 100%;
            width: 100%;
            display: block;
            pointer-events: none;
            opacity: 0;
            box-shadow: $popOverBoxShadow;

            //transition:opacity 0.2s ease;
        }
    }
    #header .bb_menu .bb_menu-list[data-depth="0"] {
        box-shadow: -30rem 0 $menuSidesBg, 30rem 0 $menuSidesBg, -30rem 1px $menuSidesBg, 30rem 1px $menuSidesBg;
        justify-content: space-between;
        flex-direction: row;
        text-transform: uppercase;
        
        &:before {
            content: "";
            display: block;
            height: 100vw;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            background-color: $pageShadow;
            z-index: -1;
            transition: $pageShadowTransition;
            pointer-events: none;
            opacity: 0;
        }
        /*&:hover {
            &:before {
                opacity: 1;
                transition: opacity 1s ease-out 0.2s;
            }
        }*/
    }
    #header .bb_menu .bb_menu-list[data-depth="0"][data-js-active]::before {
        opacity: 1;
    }
    #header .bb_menu .bb_menu-list[data-depth="0"] > .category {
        display: block;
        flex-grow: 1;
        margin-left: auto;
        max-width: 25%;
        >.sub-menu.collapse {
            top: $submenuTop;
            opacity: 0;
            transition: opacity 0.4s ease-out;
        }
        > .dropdown-item {           
            min-height: 4.2rem;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border: $submenuArrowUpSize solid transparent;
                border-top: none;
                border-bottom-color: $submenuArrowUpColor;
                position: absolute;
                bottom: 0;
                left: 1rem;
                opacity: 0;
                z-index: 1;
            }
        }
       
    }
    #header .bb_menu .bb_menu-list[data-depth="0"] > .category[data-js-active] {
        
            > .dropdown-item {
                @include text-contrast($menuItemHoverBg);
                transition: background-color 0.15s ease-in, color 0.15s ease-in;
                background-color: $menuItemHoverBg;
                //padding: $submenuDepth1LinkPadding;
                
                //color: $menuColorHover;
                box-shadow: 0 1px $menuBg inset, 0 4px $menuItemHoverShadow inset;
                box-shadow: $menuPrimaryBtnBoxShadow;
                &:after {
                    opacity: 1;
                }
            }
            >.sub-menu.collapse {
                opacity: 1;
                pointer-events: auto;
                transition: opacity 0.4s ease-out;
            }
       // }
    }
    #header .bb_menu .bb_menu-list[data-depth="1"] {
        border-right: 1px solid $menuLinesColor;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        min-height: $subMenuHeight;
        padding: 1rem 0;
        width: $submenuWidth;
    }
    #header .bb_menu .bb_menu-list[data-depth="1"] > .category {
        display: flex;
        align-items: center;
        cursor: pointer;
        > .dropdown-item {
            padding: 0.4rem 0.5rem 0.4rem 0.75rem;

            padding: $submenuDepth1LinkPadding;
        }
        > .dropdown-submenu ~ .bb_arrows {
            display: flex;
            padding: 0;
            position: static;
            pointer-events: none;
            width: 1.5rem;
            height: 1.5rem;
            align-items: center ;
            
            
            
            background-color: transparent;
           
            > .material-icons {
                display: none;
            }
            &:after {
                content: "\276f";
                display: inline-block;
                font-size: 0.9rem;
                line-height: 1;
                margin: 0;
                
                opacity: 0.3;
                transition: opacity 0.4s, transform 0.2s;
                 transform: translateX(3px);
            }
        }
        > .dropdown-submenu {
            &:hover,
            &:focus {
                ~ .bb_arrows::after{

                    transform: translateX(8px);
                    opacity: 0.5;
                }
            }
        }
        &:hover,
        &:focus {
            background-color: $bgSubmenuLinksHover;
            > .dropdown-item {
                background-color: transparent;
            }
            > .collapse {
                opacity: 1;
                pointer-events: auto;
                transition: opacity 0.2s ease-in 0s;
            }
        }
    }
    #header .bb_menu .bb_menu-list[data-depth="1"] > .category {
        > .collapse {
            background-color: $bgSubmenuBg;
            //box-shadow: -1px 0 $menuLinesColor;
            display: flex;
            height: $subMenuHeight;
            left: $submenuWidth;
            opacity: 0;
            padding: $submenuCollapsePadding;
            //outline: 1px solid;

            pointer-events: none;
            position: absolute;
            top: 0;
            transition: opacity 0.1s ease 0.2s;
            width: $submenuContainerWidth;
            z-index: 1;
            overflow: hidden;
            align-items: flex-start;
        }
        .bb_menu-images-container {
            height: 100%;
            width: auto;
            top: 0;
            right: 0;
        }
    }
    #header .bb_menu .bb_menu-list[data-depth="2"] {
        //flex-direction: column;
        //padding-right: 1.5rem;
        
        max-height: 100%;
        width: 14rem;
        -webkit-columns:14rem;
        columns:14rem;
         -webkit-column-gap:0;
         column-gap:0;
         display: inline-block;
        
         padding: 0;
         margin: 0;
         .category {
            padding: 0;
            //outline: 1px solid red;
            overflow: hidden;
             -webkit-column-break-inside: avoid;
             break-inside: avoid-column;
             display: inline-block;
             vertical-align: top;

         }
        .dropdown-item {
            padding: 0.35rem 0.5rem;
            padding: $submenuDepth2CollapsePadding;
            z-index: 1;
            font-size: 0.735rem;
            width: 14rem;
            display: block;
            &:hover,
            &:focus {
                background-color: $bgSubmenuLinksHover;
            }
        }
        .category .bb_menu-images-container {
            display: flex;
            width: 100%;
            background-color: $bgSubmenuBg;
            left: 0;
            right: 0;
            z-index: -1;
            justify-content: flex-end;
            align-items: flex-end;
            opacity: 0;
            pointer-events: none;
            z-index: 0;
            img {
                width: 20rem;
                height: auto;
            }
        }
        .category {
            &:hover {
                .bb_menu-images-container {
                    transition: opacity 0.2s ease 0s;
                    opacity: 1;
                }
            }
        }
        &:hover,
        &:focus {
            //outline: 1px solid pink;
            //z-index: 10;
            ~ .bb_menu-images-container {
                opacity: 0;
                transition: opacity 0.2s ease 0.3s;
            }
        }
    }
    @-moz-document url-prefix() {
        #header .bb_menu .bb_menu-list[data-depth="0"] > .category > .sub-menu.collapse {
            top: $submenuFFTop;
        }
        #header .bb_menu .bb_menu-list[data-depth="1"] > .category .bb_menu-images-container {
            right: $menuImgContFF;
        }
    }
}
