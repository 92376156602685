.bb_arrows {
    margin: 0;
    color: inherit;
    width: 3rem;
    height: 3rem;
    display: none;
    font-size: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    text-align: center;
    outline: none;
    appearance: none;
    opacity: 0.7;
    .material-icons {
        pointer-events: none;
    }
    &:focus {
        outline: none;
    }
}

.bb_menu [data-depth="0"] .bb_arrows {
    background-color: $bbArrowsBg;
    color: $bbArrowsColor;
}

.bb_menu .bb_menu-list:not([data-depth="0"]) .bb_arrows {
    background-color: $bbArrowsBg;
   
}

[data-js-active]~.bb_arrows .material-icons {
    transform: rotate(180deg);
}

.modal-open {  
    overflow-y: auto;
}

.modal-close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;    
    border: none;
    background: transparent;
    font-size: 1.5rem;
    height: 2rem;
    width: 2rem;
    &:focus{
        outline: none;
        box-shadow: none;
    }
}
