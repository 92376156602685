$subCategoriesPoint1:860px;
$subCategoriesPoint2:940px;

#subcategories {
    position: relative;
    text-align: center;

    @media (min-width: $subCategoriesPoint1) {
        overflow: hidden; //width: calc(100% + 2px);
    }

    ul {
        position: relative;

        @media (min-width: $subCategoriesPoint1) {
            margin: 1px; //width: calc(100% + 6rem);
        }


        &:after {
            display: none;
            content: "XL";
            position: absolute;
            top: 0;
            left: 0;
            padding: 1rem;
            background-color: white;
        }
    }

    li {
        display: inline-block; //margin-right: 1rem;
        margin-bottom: 2rem;
        vertical-align: top;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        &:after {
            display: none;
        }

        h5 {
            text-align: center;
            margin: 0;
            width: 190px;
        }


        .subcategory-name {
            font-weight: 500;
            font-size: 0.75rem;
            text-transform: uppercase;
            padding: 0.75rem 0;
            display: block;
        }
        .subcategory-image {


            width: 190px;
            background-color: $colorShadow;
            box-shadow: 0 0 0 1px $colorShadow;
            .img {
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: center;
            }
        }
    }
}



@media (min-width: 432px) {
    #subcategories ul::after {
        content: "> 432px";
    }

    #subcategories ul li {
        margin-left: 0;
        margin-right: 10px;
    }
}

@media (min-width: 660px) {
    #subcategories ul::after {
        content: "> 660px";
    }
    #subcategories ul li {
        margin-right: 1rem;
        margin-left: 0;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

@media (min-width: 767px) {
    #subcategories ul::after {
        content: "> 767px";
    }

    #subcategories ul li {
        margin-left: 1rem;
        margin-right: 1rem;
        &:nth-child(3n) {
            margin-right: 1rem;
        }
    }
}

@media (min-width: 826px) {

    #subcategories ul::after {
        content: "> 826px";
    }


    #subcategories ul li {
        margin-left: 0;
    }
}

@media (min-width: 850px) {
    #subcategories ul::after {
        content: "> 850px";
    }
    #subcategories {
        text-align: left;
    }

    #subcategories ul li {
        margin-right: calc(100%/ 3 - 190px);
        &:nth-child(3n) {

            margin-right: 0;
        }
    }
}

@media (min-width: 950px) {
    #subcategories ul::after {
        content: "> 950px";
    }
    #subcategories {
        text-align: left;
    }

    #subcategories ul li {
        margin-right: calc(100%/ 3 - 177px);
        &:nth-child(3n) {

            margin-right: 0;
        }
    }
}

@media (min-width: 1201px) {
    #subcategories ul::after {
        content: "4 cols";
    }
    #subcategories ul li {
        margin-right: calc(100%/ 4 - 185px);
        &:nth-child(3n) {
            margin-right: calc(100%/ 4 - 185px);
        }
        &:nth-child(4n) {

            margin-right: 0;
        }
    }
}

@media (min-width: 1401px) {

    #subcategories ul li {
        margin-right: calc(100%/ 4 - 178px);
        &:nth-child(3n) {
            margin-right: calc(100%/ 4 - 178px);
        }
        &:nth-child(4n) {           
            margin-right: 0;
        }
    }
}