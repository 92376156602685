$accentColor: #95c5ca;
$bodyBg: #fff;
//$accentColor:#8bd2ce;

$primaryColor:$accentColor;
$headerItemsColor: $accentColor;
//------------
//
//  SECUNDARY MENU
//
//------------
//
$menuSecundaryColor: $accentColor;
$menuSecundaryHoverColor: white;
$menuSecundaryHoverBg: $accentColor;

//------------
//
//  FOOTER
//
//------------
$footerBg: $accentColor;
$footerBeforeBg: desaturate(lighten($accentColor,5%),50%);
$footerBeforeBg: $accentColor;


